const SvgCircle = () => {
  return (
    <svg width="100%" height="100%" className="absolute h-full absolute">
      <defs>
        <pattern id="gallery-dots" x="0" y="0" width="70" height="70" patternUnits="userSpaceOnUse">
          <path
            d="M1068.7,916.8a23.4,23.4,0,1,0,23.3,23.4A23.345,23.345,0,0,0,1068.7,916.8Z"
            transform="translate(-1045.4 -916.8)" fill="#0060F5" opacity="0.2"
          />
        </pattern>
      </defs>

      <rect x="0" y="0" width="100%" height="100%" fill="url(#gallery-dots)" className="opacity-30"/>
    </svg>
  )
}

export default SvgCircle
