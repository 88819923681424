const SvgHeart = () => {
  return (
    <svg width="100%" height="100%" className="absolute top-0 h-full">
      <defs>
        <pattern id="about-heart" x="0" y="0" width="60" height="60" patternUnits="userSpaceOnUse">
          <path
            d="M22.9,6.1s4-5.7,10-6c7.5-.3,12.5,5.1,12.8,11.1.3,5.9-3.9,11.3-7.3,14.9A75.37,75.37,0,0,1,22.7,38.3,80.047,80.047,0,0,1,7.2,25.8C3.8,22.1-.3,16.7.1,10.8.4,4.9,5.5-.4,13,0,19,.4,22.9,6.1,22.9,6.1Z"
            fill="#db5596"
            transform="translate(19.663 -0.019) rotate(20)"/>
        </pattern>
      </defs>

      <rect x="0" y="0" width="100%" height="100%" fill="url(#about-heart)" className="opacity-30"></rect>
    </svg>
  )
}

export default SvgHeart
