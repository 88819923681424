import instagram from '../../images/network/Instagram.svg'
import tiktok from '../../images/network/TikTok.svg'
import twitter from '../../images/network/Twitter.svg'

const SocialNetworkContainer = () => {
  return (
    <div className="fixed left-5 top-1/2 z-20">
      <a href={'https://www.instagram.com/lalolitabanana/'} target="_blank" rel="noreferrer" >
        <img src={instagram} alt="logo instagram"
             className="drop-shadow-xl w-8 md:w-12 3xl:w-16 hover:scale-110 hover:cursor-pointer"/>
      </a>
      <a href={'https://www.tiktok.com/@lalolitabanana'} target="_blank" rel="noreferrer" >
        <img src={tiktok} alt="logo tiktok"
             className="drop-shadow-xl w-8 md:w-12 3xl:w-16 hover:scale-110 hover:cursor-pointer"/>
      </a>
      <a href={'https://twitter.com/LaLolitaBanana'} target="_blank" rel="noreferrer" >
        <img src={twitter} alt="logo twitter"
             className="drop-shadow-xl w-8 md:w-12 3xl:w-16 hover:scale-110 hover:cursor-pointer"/>
      </a>
    </div>
  )
}

export default SocialNetworkContainer
