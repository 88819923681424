import { Routes, Route } from 'react-router-dom'
//routes
import App from '../App'
import About from '../pages/About'
import Event from '../pages/Event'
import Gallery from '../pages/Gallery'
import Shop from '../pages/Shop'
import DragRace from '../pages/DragRace'

const Router = () => {
  return (
    <Routes>
      <Route path={'/'} element={<App/>}/>
      <Route path={'/evenement'} element={<Event/>}/>
      <Route path={'/galerie'} element={<Gallery/>}/>
      <Route path={'/dragrace'} element={<DragRace/>}/>
      <Route path={'/shop'} element={<Shop/>}/>
      <Route path={'/a-propos'} element={<About/>}/>
    </Routes>
  )
}

export default Router
