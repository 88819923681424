import bananaClose from "../../images/burger/banana-close.webp";
import bananaOpen from "../../images/burger/banana-open.webp";

const SwitchBtnBurger = ({ open, setOpen }) => {
    return (
        <>
            {open
                ? <img src={bananaOpen} width={40} height={40} alt="white banana open"
                         onClick={() => setOpen(false)}
                         className="cursor-pointer"/>
                : <img src={bananaClose} width={40} height={40} alt="white banana closed"
                         onClick={() => setOpen(true)}
                         className="cursor-pointer xl:hover:animate-bounce"/>

            }
        </>
    )
}
export default SwitchBtnBurger
