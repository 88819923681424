import Page from './Page'
import Title from '../components/tools/Title'
import Text from '../components/Text'
import Footer from '../components/footer/Footer'
import SvgCircle from '../components/tools/SvgCircle'
import SvgHeart from '../components/tools/SvgHeart'
import lolita8 from '../images/lolita/Lolita_08.webp'
import lolitaGallery from '../images/lolita/Lolita_galerie.webp'

function Gallery() {

  return (
    <Page>
      <div className="relative h-full w-full bg-blue-form-02 bg-lolita-blue-sky bg-no-repeat bg-cover pb-28 overflow-x-hidden">
        <SvgCircle/>
        <section
          className="relative px-10 pt-48 flex flex-col items-start
                                md:px-20 lg:px-24 3xl:pr-80 xl:flex-row-reverse justify-around items-center">
          <div className="relative flex flex-col justify-center space-y-5 text-white
                                xl:row-span-1 xl:col-start-2 xl:col-end-4">
            <Title>
              Galerie &#128248;
            </Title>
            <Text>
              Retrouve toutes les photos de Lolita en lien avec les différents <br/>
              événements et tous les moments partagés ensemble !
            </Text>
          </div>
          <img
            src={lolita8} alt=""
            className="relative translate-x-1/3 md:translate-x-3/4 xl:translate-x-16 2xl:translate-x-1/4 3xl:translate-x-28
                                xl:row-span-1 xl:col-start-4 xl:col-end-6
                                max-w-sm md:max-w-lg lg:max-w-xl 3xl:max-w-2xl"/>
        </section>
        <div className="w-full flex justify-center align-top md:px-10">
          <img
            src={lolitaGallery} alt=""
            className="relative"
          />
        </div>
      </div>
      <Footer color={'bg-lolita-pink'} form={'bg-pink-form-footer'}>
        <SvgHeart/>
      </Footer>
    </Page>
  )
}

export default Gallery
