import Menu from './Menu'

const Modal = () => {
  return (
    <div className="w-screen h-screen fixed top-0 left-0 bg-lolita-blue flex justify-center items-center z-40">
      <Menu isVertical={true}/>
    </div>
  )
}
export default Modal
