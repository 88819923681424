import { Link } from 'react-router-dom'
import lolita1 from '../../images/lolita/Lolita_01.webp'
import lolita2 from '../../images/lolita/Lolita_05.webp'
import lolita3 from '../../images/lolita/Lolita_06.webp'
import lolita4 from '../../images/lolita/Lolita_02.webp'
import logo from '../../images/logo/logo.webp'
import instagram from '../../images/network/Instagram.svg'
import tiktok from '../../images/network/TikTok.svg'
import twitter from '../../images/network/Twitter.svg'

import useWindowSize from '../../hooks/useWindowSize'

const Footer = ({ color, form, children }) => {

  const { windowSize } = useWindowSize()

  return (
    <footer className={`relative z-30 w-full h-full px-5 ${color} flex flex-col items-center justify-around ${form} bg-no-repeat bg-cover`}>
      {children}
      <p
        className={`relative text-white text-xs pt-16 ${windowSize.height < 730 ? 'pb-4' : 'pb-8'} ${windowSize.height < 800 ? '!hidden' : ''} md:text-2xl lg:text-3xl lg:pt-32 lg:pb-16`}>
        RETROUVEZ-MOI SUR LES RÉSEAUX SOCIAUX
      </p>
      <div className={`relative flex justify-center items-center space-x-2 md:py-5 ${windowSize.height < 800 ? '!hidden' : ''}`}>
        <img src={lolita1} alt="" className={`w-16 md:w-32 lg:w-44 ${windowSize.height < 550 ? '!w-12' :''}`}/>
        <img src={lolita2} alt="" className={`w-16 md:w-32 lg:w-44 ${windowSize.height < 550 ? '!w-12' :''}`}/>
        <img src={lolita3} alt="" className={`w-16 md:w-32 lg:w-44 ${windowSize.height < 550 ? '!w-12' :''}`}/>
        <img src={lolita4} alt="" className={`w-16 md:w-32 lg:w-44 ${windowSize.height < 550 ? '!w-12' :''}`}/>
      </div>
      <ul className={`relative w-full px-5 py-8 flex flex-col items-center space-y-5
                      lg:flex-row lg:justify-between
                      ${windowSize.height < 550 ? '!flex-row !py-5' : ''}`}>
        <li className="w-full inline-flex justify-center lg: w-auto">
          <img src={logo} alt="Lolita Banana"
               className={`w-24 md:w-36 ${windowSize.height < 550 ? '!w-[24rem]' : ''}`}/>
        </li>
        <li className="w-full text-center lg:w-auto">
          <Link to={'/a-propos'} className="text-white">
            <p className="text-xs font-bold">A propos</p>
            <p className={`text-xs md:text-lg ${windowSize.height < 550 ? '!text-xs' : ''}`}>Lolita Banana</p>
          </Link>
        </li>
        <li className="w-full text-center lg:w-auto text-white">
          <p className="text-xs font-bold">Événements</p>
          <a href={'https://www.instagram.com/thebrunchandqueen/'} target={'_blank'} rel="noreferrer">
            <p className={`text-xs md:text-lg ${windowSize.height < 550 ? '!text-xs' : ''}`}>The Brunch & Queen</p>
          </a>
          <a href="src/components/footer/Footer" target={'_blank'} rel="noreferrer">
            <p className={`text-xs md:text-lg ${windowSize.height < 550 ? '!text-xs' : ''}`}>DragRace France</p>
          </a>
        </li>
        <li className="w-full text-center lg:w-auto">
          <Link to={'/galerie'} className="text-white">
            <p className="text-xs font-bold">Galerie</p>
            <p className={`text-xs md:text-lg ${windowSize.height < 550 ? '!text-xs' : ''}`}>Voir mes photos</p>
          </Link>
        </li>
        <li className="w-full text-center lg:w-auto">
          <Link to={'mailto:#'} className="text-white">
            <p className="text-xs font-bold">Contact</p>
            <p className={`text-xs md:text-lg ${windowSize.height < 550 ? '!text-xs' : ''}`}>Mail</p>
          </Link>
        </li>
        <li className="w-full text-center lg:w-auto">
          <div className="flex flex-row justify-center space-x-5">
            <a href={'https://www.instagram.com/lalolitabanana/'} target="_blank" rel="noreferrer">
              <img src={instagram} alt="Instagram"
                   className="drop-shadow-xl w-8 md:w-10 hover:scale-110 hover:cursor-pointer"/>
            </a>
            <a href={'https://www.tiktok.com/@lalolitabanana'} target="_blank" rel="noreferrer">
              <img src={tiktok} alt="TikTok"
                   className="drop-shadow-xl w-8 md:w-10 hover:scale-110 hover:cursor-pointer"/>
            </a>
            <a href={'https://twitter.com/LaLolitaBanana'} target="_blank" rel="noreferrer">
              <img src={twitter} alt="Twitter"
                   className="drop-shadow-xl w-8 md:w-10 hover:scale-110 hover:cursor-pointer"/>
            </a>
          </div>
        </li>
      </ul>
      <ul className="w-full mb-10  space-x-10  flex justify-center items-center ">
        <li>
          <span className="relative text-white text-xs">
            Crédits photos : <strong>Anne Sophie Dikansky</strong> & <strong>Lahoucine Ait El Fassi</strong>
          </span>
        </li>
        <li>
          <span className="relative text-white text-xs">
            Designed by : <a href="http://www.ophelie-melili.pro" target="_blank"><strong>Ophélie MELILI </strong></a>
            & Developed by : <strong>Gaëtan MEURANT</strong>
          </span>
        </li>
        <li>
          <span className="relative mb-5 text-white text-xs">
            Copyright 2022®
          </span>
        </li>
      </ul>
    </footer>
  )
}

export default Footer
