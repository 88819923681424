import { Link } from 'react-router-dom'
import { Banana } from '../components/tools/Banana'
import Footer from '../components/footer/Footer'
import Text from '../components/Text'
import Title from '../components/tools/Title'
import SvgHeart from '../components/tools/SvgHeart'
import SvgCircle from '../components/tools/SvgCircle'
import Page from './Page'
import logo from '../images/logo/logo.webp'
import lolita5 from '../images/lolita/Lolita_05.webp'
import lolita3 from '../images/lolita/Lolita_04.webp'

function About() {
  return (
    <Page>
      <div className="h-full w-full bg-pink-form bg-lolita-pinky bg-no-repeat bg-cover overflow-x-hidden">
        <section
          className="relative pt-48 pb-32 flex flex-col items-start xl:grid grid-rows-1 grid-cols-6 xl:items-center">
          <SvgHeart/>
          <div className="relative px-12 flex flex-col justify-center space-y-5 text-white
                                xl:row-span-1 xl:col-start-2 xl:col-end-4">
            <Title>
              A propos <Banana/>
            </Title>
            <Text>
              Une question ? Envie de travailler ensemble ? <br/>
              <Link to={'#'}>Rencontrons-nous !</Link>
            </Text>
          </div>
          <img src={lolita5} alt=""
               className="realtive translate-x-1/3 md:translate-x-3/4 2xl:translate-x-1/4 xl:translate-x-0
                                xl:row-span-1 xl:col-start-4 xl:col-end-6
                                max-w-sm md:max-w-lg lg:max-w-xl"/>
          <div className="relative px-12 mt-20 flex flex-col justify-center space-y-5 text-white
                            xl:row-span-2 xl:col-start-4 xl:col-end-7">
            <Title>
              Lolita Banana
            </Title>
            <Text>
              Tout droit venue du Mexique, cette danseuse professionnelle est une véritable showgirl !
              <br/><br/>Pas endiablés, grands écarts et autres acrobaties, elle dégage une énergie solaire follement
              communicative.
              <br/><br/>Le Brunch and Queen, qu’elle anime tous les week-ends depuis 3 ans est devenue une véritable
              institution à Paris.
              <br/><br/>Lolita Banana fait partie des 10 Queens de Drag Race France.
              <br/>Elle est la première mexicaine née au Mexique à rentrer dans l’histoire de Drag Race et à participer
              dans la compétition.
            </Text>
          </div>
          <div className="relative w-full flex flex flex-col items-end
                                 mt-8 xl:mt-20
                                xl:row-span-1 xl:col-start-2">
            <img src={lolita3} alt=""
                 className="relative -scale-x-100 -rotate-12 translate-x-1/4 md:translate-x-3 xl:translate-x-20
                         max-w-xs md:max-w-sm lg:max-w-lg xl:max-w-xl 3xl:max-w-7xl"/>
            <img src={logo} alt="Lolita Banana"
                 className="w-1/5 absolute -bottom-10 right-36 md:right-52 lg:right-72 lg:-bottom-16
                            xl:w-3/4 xl:-right-48 xl:bottom-0
                            2xl:max-w-2xl"/>
          </div>
        </section>
      </div>
      <Footer color={'bg-lolita-blue'} form={'bg-blue-form-footer'}>
        <SvgCircle/>
      </Footer>
    </Page>
  )
}

export default About
