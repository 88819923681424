import MenuLink from './MenuLink'
import { useLocation } from 'react-router-dom'
import instagram from '../../images/network/Instagram.svg'
import tiktok from '../../images/network/TikTok.svg'
import twitter from '../../images/network/Twitter.svg'
import useSwitchColor from '../../hooks/useSwitchColor'
import useWindowPosition from '../../hooks/useWindowPosition'

const Menu = ({ isVertical, }) => {
  const switchColor = useSwitchColor()
  const { pageY } = useWindowPosition()
  const { pathname } = useLocation()

  return (
    <ul className={isVertical ? 'menu-vertical' : 'menu-horizontal'}>
      <li className={`${pathname === '/' ? 'font-bold' : ''}`}>
        <MenuLink text="Accueil" link={'/'}/>
      </li>
      <li className={`${pathname === '/evenement' ? 'font-bold' : ''}`}>
        <MenuLink text="Événements" link={'/evenement'}/>
      </li>
      <li className={`${pathname === '/galerie' ? 'font-bold' : ''}`}>
        <MenuLink text="Galerie" link={'/galerie'}/>
      </li>
      <li className={`${pathname === '/dragrace' ? 'font-bold' : ''}`}>
        <MenuLink text="DragRace" link={'/dragrace'}/>
      </li>
      <li className={`${pathname === '/a-propos' ? 'font-bold' : ''}`}>
        <MenuLink text="Lolita" link={'/a-propos'}/>
      </li>
      <li className={`${pathname === '/shop' ? 'font-bold' : ''}`}>
        <MenuLink text="Shop" link={'/shop'}/>
      </li>
      <li>
        <a
          className={`font-Moon text-4xl ${switchColor || pageY > 200 ? 'text-white hover:text-black' : 'text-black hover:text-white'}`}
          href={'mailto:contact@lolitabanana.com'}>Contact</a>
      </li>
      {isVertical &&
        <li>
          <div className="flex flex-row justify-center space-x-5 mt-10">
            <a href={'https://www.instagram.com/lalolitabanana/'} target="_blank" rel="noreferrer">
              <img src={instagram} alt="Instagram"
                   className="drop-shadow-xl w-8 md:w-10 hover:scale-110 hover:cursor-pointer"/>
            </a>
            <a href={'https://www.tiktok.com/@lalolitabanana'} target="_blank" rel="noreferrer">
              <img src={tiktok} alt="TikTok"
                   className="drop-shadow-xl w-8 md:w-10 hover:scale-110 hover:cursor-pointer"/>
            </a>
            <a href={'https://twitter.com/LaLolitaBanana'} target="_blank" rel="noreferrer">
              <img src={twitter} alt="Twitter"
                   className="drop-shadow-xl w-8 md:w-10 hover:scale-110 hover:cursor-pointer"/>
            </a>
          </div>
        </li>
      }
    </ul>
  )
}

export default Menu
