import { NavLink } from 'react-router-dom'
import useSwitchColor from '../../hooks/useSwitchColor'
import useWindowPosition from '../../hooks/useWindowPosition'

const MenuLink = ({ text, link }) => {
  const switchColor = useSwitchColor()
  const { pageY } = useWindowPosition()

  return (
    <NavLink to={link}>
           <span
             className={`font-Moon text-4xl ${switchColor || pageY > 200 ? 'text-white hover:text-black' : 'text-black hover:text-white'}`}>
             {text}
           </span>
    </NavLink>
  )
}

export default MenuLink
