import { Scrollbar } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Mousewheel, Pagination } from 'swiper'
import { NavLink } from 'react-router-dom'
import { Banana } from './components/tools/Banana'
import useWindowSize from './hooks/useWindowSize'
import Footer from './components/footer/Footer'
import Title from './components/tools/Title'
import Page from './pages/Page'
import Button from './components/tools/Button'
import Text from './components/Text'
import SvgHeart from './components/tools/SvgHeart'
import SvgBanana from './components/tools/SvgBanana'
import SvgCircle from './components/tools/SvgCircle'
import lolita1 from './images/lolita/Lolita_01.webp'
import lolita2 from './images/lolita/Lolita_02.webp'
import lolita3 from './images/lolita/Lolita_06.webp'
import brunch from './images/brunch-queen.png'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

function App() {

  const { windowSize } = useWindowSize()

  return (
    <Page>
      <Swiper
        modules={[Pagination, Scrollbar, Mousewheel]}
        direction={'vertical'}
        spaceBetween={0}
        slidesPerView={'auto'}
        speed={2000}
        mousewheel={true}
        autoHeight={true}
        pagination={{ clickable: true }}
        onReachEnd={(swiper) => {
          swiper.params.mousewheel.releaseOnEdges = true
        }}
        centeredSlides={true}
        className="mySwiper">
        <SwiperSlide key={'slide 1'}>
          <section className={`relative h-screen bg-lolita-blue-sky bg-blue-form bg-cover bg-no-repeat
                               grid grid-cols-4 grid-rows-1 md:grid-rows-2 items-end lg:grid-rows-2 xl:grid-rows-3
                               ${windowSize.height < 550 ? '!flex flex-row justify-around items-center': ''}`}>
            <SvgCircle/>
            <div className="relative flex flex-col justify-center space-y-5 text-white col-span-full pl-10
                            md:col-start-2 md:col-end-4 md:row-span-1 md:pl-0 xl:row-span-2 xl:col-start-3 xl:col-end-5
                            xl:pl-10 2xl:pl-0">
              <Title>Bienvenue <Banana/> &#128139;</Title>
              <span className="block font-bold z text-xs mt-4 lg:text-sm">
                Drag Queen Franco Mexicaine
              </span>
              <Text>
                SHOOOOW TIME 🔥 <br/>
              </Text>
              <Text>
                Ici <strong>Lolita Banana</strong> !
                <br/>Découvrez-moi à travers mon site web !
              </Text>
            </div>
            <img src={lolita1}
                 alt="Lolita souriante sur une colonne de pancakes entourée de néons jaunes, roses et blancs"
                 className={`-translate-x-1/2 md:translate-x-10 2xl:translate-x-1/4
                            col-star-auto col-end-5 md:col-start-3 md:col-end-4 md:row-span-2 xl:col-start-1
                            max-w-xs md:max-w-lg
                            ${windowSize.height < 730 ? 'max-w-[17rem]' : 'xl:max-w-2xl'}
                            ${windowSize.height < 650 ? '!max-w-[18rem]' : ''}
                            ${windowSize.height < 570 ? '!max-w-[20rem] !-translate-x-0' : ''}`}/>

          </section>
        </SwiperSlide>
        <SwiperSlide key={'slide 2'}>
          <section className={`relative h-screen bg-lolita-pinky bg-pink-form-home bg-cover
                              grid grid-cols-4 grid-rows-1 md:grid-rows-2 items-end lg:grid-rows-3
                              ${windowSize.height < 550 ? '!flex flex-row justify-around items-center': ''}`}>
            <SvgHeart/>
            <div className="relative flex flex-col justify-center space-y-5 text-white col-span-full pl-10
                               md:col-start-2 md:col-end-4 md:row-span-1 md:pl-0 xl:row-span-2 xl:col-start-3 xl:col-end-5
                               xl:pl-10 2xl:pl-0">
              <Title>A propos</Title>
              <Text>
                Découvrez mon univers à travers ma bio !
              </Text>
              <NavLink to={'/a-propos'}>
                <Button text="En savoir +"/>
              </NavLink>
            </div>
            <img src={lolita2}
                 alt="Lolita souriante sur une colonne de pancakes entourée de néons jaunes, roses et blancs"
                 className={`-translate-x-1/2 translate-y-10  md:translate-x-2 lg:translate-y-24 2xl:translate-x-[20%]
                             col-star-auto col-end-5 md:col-start-3 md:col-end-4 md:row-span-2 xl:col-start-1
                             max-w-xs md:max-w-lg
                             ${windowSize.height < 730 ? 'max-w-[17rem]' : 'lg:max-w-2xl'}
                             ${windowSize.height < 650 ? '!max-w-[18rem]' : ''}
                             ${windowSize.height < 570 ? '!max-w-[20rem] !-translate-x-0' : ''}`}/>
          </section>
        </SwiperSlide>
        <SwiperSlide key={'slide 3'}>
          <section className={`relative h-screen px-10 bg-lolita-lemon bg-yellow-form bg-cover
                               flex flex-col justify-evenly items-center lg:flex-row
                               ${windowSize.height < 550 ? '!flex-row justify-around items-center': ''}`}>
            <SvgBanana/>
            <div className="relative flex flex-col justify-center space-y-5 text-white col-span-full">
              <Title>Événements &#128139;</Title>
              <Text>
                Tu souhaites participer à un événement ? <br/>
                Retrouve toutes les informations ci-dessous !
              </Text>
              <NavLink to={'/evenement'}>
                <Button text="En savoir +" bleu={true}/>
              </NavLink>
            </div>
            <img src={brunch}
                 alt="Lolita souriante sur une colonne de pancakes entourée de néons jaunes, roses et blancs"
                 className={`relative col-span-full max-w-2xs md:max-w-md lg:max-w-lg xl:max-w-xl 3xl:max-w-2xl
                 ${windowSize.height < 730 ? 'max-w-[17rem]' : 'max-w-sm'} 
                 ${windowSize.height < 650 ? '!max-w-[18rem]' : ''}
                 ${windowSize.height < 570 ? '!max-w-[20rem] !-translate-x-0' : ''}`}/>
          </section>
        </SwiperSlide>
        <SwiperSlide key={'slide 4'}>
          <section className={`h-screen bg-lolita-blue-sky bg-blue-form-02 bg-cover bg-no-repeat
                               grid grid-cols-4 grid-rows-1 md:grid-rows-2 items-end lg:grid-rows-3
                               ${windowSize.height < 550 ? '!flex flex-row justify-around items-center': ''}`}>
            <SvgCircle/>
            <div className="relative flex flex-col justify-center space-y-5 text-white col-span-full pl-10
                               md:col-start-2 md:col-end-4 md:row-span-1 md:pl-0 lg:row-span-2 xl:row-span-2 xl:col-start-3 xl:col-end-5
                               xl:pl-10 2xl:pl-10">
              <Title>Galerie &#128248;</Title>
              <Text>
                Retrouve toutes les photos de Lolita <br/>
                en lien avec les différents événements <br/>
                et tous les moments partagés ensemble !
              </Text>
              <NavLink to={'/galerie'}>
                <Button text="En savoir +"/>
              </NavLink>
            </div>
            <img src={lolita3}
                 alt="Lolita souriante sur une colonne de pancakes entourée de néons jaunes, roses et blancs"
                 className={`-translate-x-1/2 md:translate-x-8 lg:translate-x-1/4 xl:translate-x-10 2xl:translate-x-1/4
                             col-star-auto col-end-5 md:col-start-3 md:col-end-4 md:row-span-2 xl:col-start-1
                             max-w-xs md:max-w-xl
                             ${windowSize.height < 730 ? 'max-w-[17rem]' : 'xl:max-w-2xl'}
                             ${windowSize.height < 650 ? '!max-w-[18rem]' : ''}
                             ${windowSize.height < 570 ? '!max-w-[20rem] !-translate-x-0' : ''}`}/>
          </section>
        </SwiperSlide>
        <SwiperSlide key={'slide 5'}>
          <div className="h-screen bg-lolita-pink bg-pink-form-footer bg-cover bg-no-repeat flex justify-center items-center">
            <SvgHeart/>
            <Footer/>
          </div>
        </SwiperSlide>
      </Swiper>
    </Page>
  )
}

export default App
