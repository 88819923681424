const Button = ({ text, bleu }) => {
  return (
    <button className="flex justify-start">
            <span className={`text-2xl font-bold font-Moon px-10 py-2 border border-4 border-lolita-lemon 
                             rounded ${bleu ? 'border-lolita-blue-sky  neon-blue' : 'border-lolita-lemon  neon'} hover:scale-110`}>
             {text}
            </span>
    </button>
  )
}

export default Button
