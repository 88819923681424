import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import Router from './config/Router'
import reportWebVitals from './reportWebVitals'
import './css/index.css'
import ScrollToTop from './components/ScrollToTop'

const root = ReactDOM.createRoot (document.getElementById ('root'))
root.render (
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop/>
      <Router/>
    </BrowserRouter>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals ()
