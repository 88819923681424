import Footer from '../components/footer/Footer'
import Text from '../components/Text'
import Title from '../components/tools/Title'
import Page from './Page'
import SvgBanana from '../components/tools/SvgBanana'
import SvgHeart from '../components/tools/SvgHeart'
import event from '../images/brunch-queen.png'
import ad from '../images/ad.jpeg'

function Event() {
  return (
    <Page>
      <div className="relative h-full w-full bg-yellow-form-02 bg-lolita-lemon bg-no-repeat bg-cover overflow-x-hidden">
        <SvgBanana/>
        <section className="relative px-10 pt-48 flex flex-col items-start
                                md:px-20 lg:px-24 xl:flex-row-reverse justify-around items-center">
          <div className="w-full my-10 xl:w-1/3 flex flex-col items-start justify-center space-y-5">
            <Title>Evenements &#128139;</Title>
            <Text>
              Rendez-vous tous les samedis et tout les dimanches au restaurant Acqua e Farina autour d'un buffet
              gargantuesque pour tout le tout premier Drag Brunch All you can eat.
            </Text>
          </div>
          <img src={event} alt="" className="relative xl:w-1/2 2xl:w-1/3"/>
        </section>
        <section className="px-10 pt-48 pb-32 3xl:pb-48 flex flex-col items-start
                                 md:px-20 lg:px-24 xl:flex-row justify-around items-center">
          <article className="relative w-full xl:w-2/4 space-y-10 mb-10">
            <Title>The Brunch</Title>
            <Text>
              Chaque samedi et chaque dimanche THE BRUNCH AND QUEEN vous accueille au
              Restaurant Acqua e Farina autour d'un buffet gargantuesque pour le tout premier
              Drag Brunch All you can eat de la Capital, vous pourrez déquster un large choix de produits
              entièrement fait maison comme nos fameuses lasagnes
              &#171; bolognaises ou végétariennes &#187;, des pancakes minute ou encore le traditionnel tiramisu,
              sans oublier les indispensables classiques de tous brunchs
              que se respectent &#58; oeuf brouillé, bacon, brioche artisanal..
            </Text>
            <Text>
              Les boissons sont elles aussi de la partie et à volonté !
              Nous préparons pour vous: thé glacé, citronnade, chocolat chaud à l'italienne café et thé.
            </Text>
            <Text>
              Au programme également &#58;
              <strong> les plus belles Queens de Paris</strong>,
              du Show, de la convivialité et le tout à volonté...
            </Text>
            <Text>
              La formule est parfaite pour un moment fun et festif
              à savourer avec qui vous le souhaitez...
            </Text>
            <Text>
              &#42; Réservation fortement conseillée !
              Info et réservation :
              <a href="https://www.acquaefarinaparis.com" target="_blank" rel="noreferrer">
                <strong> www.acquaefarinaparis.com</strong>
              </a>
            </Text>
            <ul>
              <li>
                <p className="font-bold">PRIX BUFFET À VOLONTÉ :</p>
              </li>
              <li>
                <Text>- samedi 30&#x20AC;</Text>
              </li>
              <li>
                <Text>- dimanche 35&#x20AC;</Text>
              </li>
            </ul>
          </article>
          <img src={ad} alt=""
               className="relative -rotate-12 xl:rotate-12 xl:w-2/4 xl:translate-x-5"/>
        </section>
      </div>
      <Footer color={'bg-lolita-pink'} form={'bg-pink-form-footer'}>
        <SvgHeart/>
      </Footer>
    </Page>
  )
}

export default Event
