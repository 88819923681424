import Page from './Page'
import Footer from '../components/footer/Footer'
import SvgHeart from '../components/tools/SvgHeart'
import SvgCircle from '../components/tools/SvgCircle'

function Shop () {
  return (
    <Page>
      <div className="h-screen w-screen text-white bg-pink-form bg-lolita-pinky bg-no-repeat bg-cover flex justify-center items-center overflow-x-hidden">
        <SvgHeart/>
        <h1 className="relative flex font-bold font-Flower text-3xl mb-4 md:text-5xl lg:text-6xl xl:text-6xl">
          À venir prochainement
        </h1>
      </div>
      <Footer color={'bg-lolita-blue'} form={'bg-blue-form-footer'}>
        <SvgCircle/>
      </Footer>
    </Page>
  )
}

export default Shop
