const SvgBanana = () => {
  return (
    <svg width="100%" height="100%" className="absolute h-full">
      <defs>
        <pattern id="event-banana" x="0" y="0" width="70" height="70" patternUnits="userSpaceOnUse">
          <g id="pattern-banana_1_" data-name="pattern-banana(1)" transform="translate(14.787 -1.545) rotate(17)">
            <path d="M9.6,43.774c-7.039-.96-9.6-4.8-9.6-4.8-.16-1.76,1.28-4.319,1.28-4.319C33.918,30.5,39.2,11.3,39.2,11.3,39.037,32.576,9.443,43.614,9.6,43.774Z" transform="translate(0 6.776)" fill="#d6b700"/>
            <path d="M3.3,56.629a4.86,4.86,0,0,1,.64-2.879s24.155-7.678,31.994-24c0,0,6.559-13.277,1.12-20.156,0,0-2.719.96-3.039.48,0,0-.16-3.839,2.08-4.639,0,0,3.839-.64,7.519-5.439,0,0,1.6,2.719-1.6,6.879l2.719,8.958s9.278,4.8,7.838,15.357a7.174,7.174,0,0,1-.16,1.76c-.32,4-3.2,20.156-29.114,27.195,0,0-8.958,3.519-20-1.6v-1.92Z" transform="translate(1.984 0)" fill="#d6b700"/>
          </g>
        </pattern>
      </defs>
      <rect x="0" y="0" width="100%" height="100%" fill="url(#event-banana)" className="opacity-30"></rect>
    </svg>
  )
}

export default SvgBanana
