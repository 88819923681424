import NavBar from '../components/navbar/NavBar'
import SocialNetworkContainer from '../components/navbar/SocialNetworkContainer'
import useWindowSize from '../hooks/useWindowSize'

function Page({ children }) {

  const { windowSize } = useWindowSize()

  return (
    <>
      <header>
        <NavBar/>
      </header>
      {windowSize.width > 800 &&
        <SocialNetworkContainer/>
      }
      <main className="overflow-x-clip overflow-x-hidden">
        {children}
      </main>
    </>
  )
}

export default Page
