import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import useWindowPosition from '../../hooks/useWindowPosition'
import useWindowSize from '../../hooks/useWindowSize'
import Modal from './Modal'
import SwitchBtnBurger from './SwitchBtnBurger'
import logo from '../../images/logo/logo.webp'
import Menu from './Menu'

const NavBar = () => {

  const { windowSize } = useWindowSize()
  const { pageY } = useWindowPosition()
  const [isOpen, setOpen] = useState(false)

  return (
    <>
      <nav
        className={`fixed w-full py-5 px-5 lg:px-10 flex justify-between items-center z-50 ${pageY > 200 && !isOpen ? 'bg-black/20' : ''}`}>
        <NavLink to="/">
          <img src={logo} alt="Lolita Banana"
               width={windowSize.width > 950 ? 135 : 100}
               className="cursor-pointer"
               onClick={() => setOpen(false)}/>
        </NavLink>
        {windowSize.width > 1250 && !isOpen
          ? <Menu isVertical={false}/>
          : <SwitchBtnBurger open={isOpen} setOpen={setOpen}/>
        }
      </nav>
      {isOpen && <Modal/>}
    </>
  )
}

export default NavBar
