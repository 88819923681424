import {useEffect, useState} from "react";

const useWindowPosition = () => {
    const [pageY, setPageY] = useState(0)

    useEffect(() => {
        function handleOffsetY() {
            setPageY(window.scrollY)
        }

        window.addEventListener("scroll", handleOffsetY)
        handleOffsetY()
        return () => window.removeEventListener("scroll", handleOffsetY)
    }, []);

    return { pageY }
}
export default useWindowPosition
