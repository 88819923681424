import Page from './Page'
import Footer from '../components/footer/Footer'
import Title from '../components/tools/Title'
import Text from '../components/Text'
import SvgBanana from '../components/tools/SvgBanana'
import SvgHeart from '../components/tools/SvgHeart'
import YoutubeEmbed from '../components/YoutubeEmbed'
import lolita07 from '../images/lolita/Lolita_07.webp'
import dragRace from '../images/logo/DragRace_logo.webp'

function DragRace() {
  return (
    <Page>
      <div className="relative h-full w-full bg-yellow-form-02 bg-lolita-lemon bg-no-repeat bg-cover overflow-x-hidden">
        <SvgBanana/>
        <section className="relative px-10 pt-48 flex flex-col items-start
                                md:px-20 lg:px-24 xl:flex-row-reverse justify-around items-center">
          <div className="w-full my-10 xl:w-1/3 flex flex-col items-start justify-center space-y-5">
            <Title>Emission &#128139;</Title>
            <Text>
              Drag Race France est une émission de téléréalité française basée sur la série télévisée américaine
              RuPaul’s Drag Race. L’émission est un concours de drag queens au cours de laquelle est sélectionnée la «
              prochaine grande reine du drag français ».
            </Text>
          </div>
          <img src={lolita07} alt="" className="relative rotate-12 xl:rotate-12 xl:w-2/4 xl:translate-x-5
                                     max-w-xs md:max-w-sm lg:max-w-lg xl:max-w-xl 3xl:max-w-3xl"/>
        </section>
        <section className="px-10">
          <YoutubeEmbed embedId="xD-fg5D_a2A" />
        </section>
        <section className="px-10 pt-48 pb-32 flex flex-col items-start
                                 md:px-20 lg:px-24 xl:flex-row justify-around items-center">
          <img src={dragRace} alt="Logo de DragRace France" className="relative"/>
        </section>
      </div>
      <Footer color={'bg-lolita-pink'} form={'bg-pink-form-footer'}>
        <SvgHeart/>
      </Footer>
    </Page>
  )
}

export default DragRace
